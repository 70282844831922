<template>
  <section>
    <article
      v-if="activeRequestId"
      class="message is-warning"
    >
      <div class="message-body">
        <span class="icon"><i class="fa fa-spinner rotate"></i></span>
        <strong>
          Pool DNIS {{ activeRequestMethod | filterCapitalize }} in Progress: {{ activeRequestId }}
        </strong>
      </div>
    </article>

    <div class="card bottom10">
      <div class="card-content">
        <b-table
          :data="results.data"
          :paginated="results.data.length > 20"
          :per-page="20"
          :current-page.sync="results.currentPage"
          :loading="loading"
          narrowed
          striped
          bordered
        >
          <b-table-column
            v-slot="props"
            field="createdAt"
            label="Created At"
          >
            {{ props.row.createdAt | filterLocalTimeString }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="type"
            label="Type"
          >
            <span
              class="tag"
              :class="{
                'is-success': props.row.type === 'import',
                'is-danger': props.row.type === 'delete',
                'is-warning': props.row.type === 'unassign'
              }"
            >
              {{ props.row.type | filterCapitalize }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="file"
            label="File"
          >
            <a @click.stop="getDNISHisoryCSV(props.row.file)">
              <span class="icon"><i class="fa fa-download"></i></span> {{ props.row.file | fileName }}
            </a>
          </b-table-column>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No History Records Found</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>

      <div class="card-footer">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <button
                class="button is-info"
                @click="getDNISHistoryList"
              >
                <span class="icon"><i class="fa fa-refresh"></i></span>
                <span>Reload</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'DNISHistory',
  filters: {
    fileName(file) {
      return file.split('_')[1];
    }
  },
  props: {
    poolId: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      results: {
        data: [],
        currentPage: 1
      },
      loading: false,
      activeRequestId: null,
      activeRequestMethod: null,
      ping: null
    };
  },
  created() {
    this.getDNISStatus();
    this.getDNISHistoryList();

    this.ping = setInterval(() => {
      this.getDNISStatus();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.ping);
  },
  methods: {
    // Import the DNIS records to Fuse.
    async getDNISHistoryList() {
      this.loading = true;
      try {
        const res = await fuseServer.getDNISHistoryList(this.poolId, this.entity);
        if (res.data.records) {
          this.results.data = res.data.records.map(function (record) {
            const [, file] = record.key.split('/');
            const [ type ] = file.split('_');
            return {
              createdAt: record.createdAt,
              type: type.toLowerCase(),
              file
            };
          });

          this.results.data.sort((a, b) => a.createdAt < b.createdAt);
        }
      } catch (err) {
        this.$store.commit('error', err, 'There was a problem getting DNIS history.');
      }

      this.loading = false;
    },
    // Get a signed S3 url to the CSV and start it's download.
    async getDNISHisoryCSV(file) {
      const [ historyId ] = file.split('.');

      try {
        const res = await fuseServer.getDNISHistoryCSV(this.poolId, historyId, this.entity);
        window.location.href = res.data.url;
      } catch (err) {
        this.$store.commit('error', err, 'There was a problem getting a DNIS history CSV.');
      }
    },
    // Check if ther'es an ongoing import happening.
    async getDNISStatus() {
      try {
        const res = await fuseServer.getDNISModificationStatus(this.poolId);
        const { requestId, method } = res.data;

        // Reload the table if an import has completed.
        if (requestId === null && this.activeRequestId !== null) {
          this.getDNISHistoryList();
          bus.$emit('reload-pool-data');
        }

        this.activeRequestId = requestId;
        this.activeRequestMethod = method;
      } catch (err) {
        this.$store.commit('error', err, 'There was a problem checking DNIS modification status.');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer .level {
  padding-bottom: 0.75rem
}

.rotate {
  animation: spinAround 1s infinite linear;
}

.icon {
  margin-right: 10px;
}
</style>
