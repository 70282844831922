<template>
  <aside
    class="menu side-nav"
    role="navigation"
  >
    <div class="side-nav-header">
      <div
        class="side-nav-brand"
      >
        <div class="navbar-item">
          <img
            src="../../assets/fuse-logo.svg"
            alt="Fuse"
            style="height: 25px"
          >
        </div>
      </div>
    </div>

    <div class="side-nav-main">
      <div
        v-for="(navgroup, i) in navigation"
        :key="i"
        class="side-nav-group"
      >
        <p
          v-if="shouldShowGroupLabel(navgroup)"
          class="menu-label"
        >{{ navgroup.group }}</p>
        <ul class="menu-list">
          <li
            v-for="(route, j) in navgroup.links.filter(route => {return routePerms(route)})"
            :id="`mn-item-${formatElementId(route.label)}`"
            :key="j"
            :data-cy-test="`${formatElementId(route.label)}`"
            @click="elementClick"
          >
            <router-link
              v-if="route.path"
              :to="route.path"
              :class="{ 'is-active' : matchActiveRoute(route) }"
            >
              <v-icon
                v-if="route.svg"
                :icon="route.svg"
                :color="matchActiveRoute(route) ? '#fff' : '#4a4a4a'"
              ></v-icon>
              <span
                v-else
                class="icon"
              ><i
                class="fa"
                :class="`fa-${route.icon}`"
              ></i></span>
              <span>{{ route.label }}</span>
            </router-link>

            <a
              v-else-if="route.link"
              target="_blank"
              :href="route.link"
            >
              <span class="icon">
                <i
                  class="fa"
                  :class="`fa-${route.icon}`"
                ></i>
              </span>
              <span>{{ route.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="side-nav-footer">
      <p>{{ version }}</p>
    </div>
  </aside>
</template>

<script>
import navs from '@/router/navigation';
import { bus } from '@/main';

const version = process.env.VUE_APP_CORE_VERSION || 'local';

export default {
  name: 'SideNav',
  computed: {
    // Check for Super User
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Check for Admin User
    isAdmin() {
      if (this.isSuper) return true;
      return this.$store.getters.isAdminUser;
    },
    // Active route
    activePath() {
      return this.$route.path;
    },
    version() {
      return version;
    },
    navigation() {
      return [ ...navs.navigation ];
    }
  },
  methods: {
    // Hides group label if all links are hidden
    shouldShowGroupLabel(group) {
      return group.links
        .map(route => this.routePerms(route))
        .reduce((acc, curr) => acc && curr);
    },
    /**
     * Display permission based routes
     * @param {Object} route - route to evaluate
     * @return {Bool} whether it should be displayed
     */
    routePerms(route) {
      if (route.isSuper) return route.isSuper === this.isSuper;
      return true;
    },
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    },
    formatElementId(value) {
      let id = '';

      if (value) {
        id = value.replace(/ /g, '-').toLowerCase();
      }

      return id;
    },
    matchActiveRoute(route) {
      // this will evaluate to true if the current path exactly matches a route
      if (route.path === this.activePath) return true;

      // we still need to check child routes, such as routes ending with an ID
      return route.childRouteNames && route.childRouteNames.includes(this.$route.name);
    }
  }
};
</script>
