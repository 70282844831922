<template functional>
  <button
    class="button"
    :disabled="disabled"
    :class="{
      'is-loading': loading,
      'is-success': type === 'success',
      'is-danger': type === 'danger',
      'is-info': type === 'info'
    }"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      class="icon"
    >
      <i :class="`fa fa-${icon}`"></i>
    </span>
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'BaseButtonDefault',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
