<template functional>
  <base-button-default
    :loading="loading"
    :disabled="disabled || !parent.$store.getters.userCanEdit"
    type="danger"
    icon="trash"
    text="Delete"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'BaseButtonDelete',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
