const navigation = [
  {
    group: 'Phones',
    links: [
      {
        path: '/dashboard',
        label: 'Dashboard',
        icon: 'phone-square'
      }
    ]
  },
  {
    group: 'Manage',
    links: [
      {
        isSuper: true,
        path: '/user/edit',
        label: 'Users',
        icon: 'users'
      }
    ]
  },
  {
    group: 'Support',
    links: [
      {
        link: 'https://redventures.atlassian.net/wiki/spaces/COHSN/pages/74429473/Fuse',
        label: 'Documentation',
        icon: 'book'
      }
    ]
  }
];

module.exports = {
  navigation
};
