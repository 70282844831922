import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import pathify, { make } from 'vuex-pathify';

import fuseServer from '../modules/fuse_server';
import { JWT_KEY } from '@/config';

Vue.use(Vuex);

const state = {
  user: null,
  errors: [],
  axiosActive: 0,
  userRequested: false,
  userFailed: false,
  userNotFound: false
};

// Export Store
export default new Store({
  state,
  mutations: {
    ...make.mutations(state),
    /**
     * Set the user data on login
     * @param {Object} data - user data from Fuse Server API
     */
    setUser(state, data) {
      state.user = data;
    },
    setAllUsers(state, data) {
      state.allUsers = data;
    },
    /**
     * Set the status of an axios request
     * @param {Bool} status - true/false
     */
    setAxiosActive(state, status) {
      state.axiosActive = status;
    },
    error(state, error, title) {
      console.dir(error); // eslint-disable-line

      let errTitle = error.title;

      if (!error.title) {
        if (title !== undefined) {
          errTitle = title;
        } else {
          errTitle = 'Something went wrong...';
        }
      }

      const errObj = {
        time: Date.now(),
        title: errTitle,
        stack: error.stack || null,
        message: error.message || null
      };

      if (error.response) {
        errObj.status = error.response.status;
        errObj.statusText = error.response.statusText;
        errObj.data = error.response.data;
      }

      state.errors.push(errObj);
    },
    removeError(state, error) {
      state.errors = state.errors.filter(e => e.time !== error.time);
    },
    removeAllErrors(state) {
      state.errors = [];
    }
  },
  getters: {
    // Admin Env
    env() {
      return process.env.NODE_ENV;
    },
    // Get whether a user is a super user
    isSuperUser(state) {
      return state.user && state.user.permission && state.user.permission.isSuper;
    }
  },
  actions: {
    async getCurrentUser({ commit }) {
      try {
        const userJwt = window.localStorage.getItem(JWT_KEY);
        const userData = await fuseServer.authorizeUser(userJwt);

        commit('SET_USER', userData);
        return userData;
      } catch (err) {
        if (!err.response || (err.response && err.response.status !== 404 && err.response.status !== 401)) {
          err.title = 'Failed to get current user';
          commit('error', err);
          commit('SET_USER_FAILED', true);
          return null;
        }
        if (err.response.status === 401) {
          return null;
        }
        if (err.response.status === 404) {
          commit('SET_USER_NOT_FOUND', true);
          return null;
        }

        throw err;
      }
    }
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [pathify.plugin]
});
