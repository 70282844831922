<template>
  <section>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Delete Routing Group?</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section class="modal-card-body">
        Are you sure you want to delete routing group with ID: {{ routingGroupId }} ?
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          @click="deleteRoutingGroup"
        >Delete</button>
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'DeleteRoutingGroup',
  props: ['poolId', 'routingGroupId'],
  data() {
    return {
      deleteRoutingGroupObj: null
    };
  },
  methods: {
    async deleteRoutingGroup() {
      try {
       const res = await fuseServer.deleteRoutingGroup(this.poolId, this.routingGroupId);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Delete routing group OK',
            type: 'is-success',
            duration: 2000
          });

          this.deleteRoutingGroupObj = null;
          bus.$emit('reload-routing-groups');
          this.closeModal();
        }
      } catch(err) {
        this.$store.commit('error', err, 'Error deleting routing group');
      }
    },
    closeModal() {
      bus.$emit('close-delete-routing-group-modal');
    }
  }
};
</script>
