// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Buefy from 'buefy';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';
import App from './App.vue';
import router from './router';
import store from './store';
import baseComponents from './plugins/baseComponents';
import baseFilters from './plugins/baseFilters';

Vue.config.productionTip = false;

// Enable Vue Dev Tools and debugging in staging
if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true;
  Vue.config.debug = true;
  Vue.config.silent = false;
}

// Register component libraries and plugins.
// Chartkick Vue2 docs: https://github.com/ankane/vue-chartkick/blob/v0.6.1/README.md
Vue.use(VueChartkick, { adapter: Chart });
Vue.use(Buefy, { defaultIconPack: 'fa' });
Vue.use(baseFilters);
Vue.use(baseComponents);

// Re-usable component for cross component communication.
// eslint-disable-next-line vue/require-name-property
export const bus = new Vue({}); // eslint-disable-line import/prefer-default-export

/* eslint-disable no-new */
// eslint-disable-next-line vue/require-name-property
window.vm = new Vue({
  router,
  store,
  mounted() {
    // Axios Requests for loading indicator
    this.$on('axios-active', function (val) {
      this.$store.commit('setAxiosActive', val);
    });
  },
  render: h => h(App)
}).$mount('#app');
