<template>
  <section>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ status }}</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <pre>{{ routingGroupLeases }}</pre>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'SearchRoutingGroup',
  props: ['poolId', 'routingGroupId'],
  data() {
    return {
      routingGroupLeases: [],
      status: 'Loading ...'
    };
  },
  async created() {
    this.routingGroupLeases = [];
    try {
      const res = await fuseServer.getRoutingGroupLeases(this.poolId, this.routingGroupId);

      if (res.status === 200) {
        for (let i = 0; i < res.data.length; i++) {
          this.routingGroupLeases.push(res.data[i]);
        }
      }

      this.routingGroupLeases = JSON.stringify(this.routingGroupLeases, null, 2);
      this.status = 'Phone Numbers';
    } catch(err) {
      this.$store.commit('error', err, 'Error getting routing group leases');
    }
  },
  methods: {
    closeModal() {
      bus.$emit('close-search-routing-group-modal');
    }
  }
};
</script>
