<template>
  <div class="card bottom10">
    <div class="card-content">
      <DNISCSVUpload
        :file.sync="file"
        :valid.sync="valid"
      />

      <transition name="fade">
        <section v-if="requestId !== null">
          <div class="notification is-success">
            <span class="icon"><i class="fa fa-check"></i></span>
            {{ method | filterCapitalize }} DNIS request received: {{ requestId }}. Redirecting to DNIS History...
          </div>
        </section>
      </transition>
    </div>

    <div class="card-footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <button
              class="button"
              :class="{
                'is-loading': uploading,
                'is-success': method === 'import',
                'is-warning': method === 'unassign',
                'is-danger': method === 'delete'
              }"
              :disabled="!valid"
              @click="save"
            >
              <span class="icon">
                <i
                  aria-hidden="true"
                  class="fa"
                  :class="{
                    'fa-plus': method === 'import',
                    'fa-power-off': method === 'unassign',
                    'fa-trash': method === 'delete'
                  }"
                />
              </span>
              <span>{{ method | filterCapitalize }}</span>
            </button>
          </div>

          <div class="level-item">
            <button
              class="button"
              @click="reset"
            >
              Clear
            </button>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <p>
              <a
                href="https://redventures.atlassian.net/wiki/spaces/COHSN/pages/3428550322/Fuse+-+Admin+CSV+Uploads"
                target="_blank"
              >
                <span class="icon">
                  <i
                    aria-hidden="true"
                    class="fa fa-info-circle"
                  />
                </span>
                <span>Need Help?</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DNISCSVUpload from '@/views/DNIS/DNISCSVUpload';
import fuseServer from '@/modules/fuse_server';
import { sleep } from '@/modules/utilities';

const methods = {
  pool: {
    import: fuseServer.postImportDNISCSV,
    unassign: fuseServer.postUnassignDNISCSV,
    delete: fuseServer.postDeleteDNISCSV
  },
  permaleases: {
    delete: fuseServer.postDeletePermaleasesCSV
  }
};

export default {
  name: 'DNISCSVForm',
  components: {
    DNISCSVUpload
  },
  props: {
    poolId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      file: null,
      valid: false,
      uploading: false,
      requestId: null
    };
  },
  watch: {
    file(file) {
      if (file === null) {
        this.reset();
      }
    }
  },
  created() {
    this.reset();
  },
  methods: {
    // Using the given method, handle uploading the csv.
    async save() {
      this.uploading = true;
      try {
        const data = new FormData();
        data.append('file', this.file);

        if (!methods[this.entity]) throw new Error(`Unexpected entity for upload: '${this.entity}'`);
        if (!methods[this.entity][this.method]) throw new Error(`Unexpected method for upload: '${this.method}'`);

        const res = await methods[this.entity][this.method](this.poolId, data);

        this.uploading = false;
        this.requestId = res.data.requestId;
        await sleep(2000);
        this.$emit('upload-successful', true);
      } catch (err) {
        this.$store.commit('error', err, `There was a problem processing the request to Fuse.`);
        this.reset();
      }

      this.uploading = false;
    },
    // Reset the form back to it's original state.
    reset() {
      this.file = null;
      this.valid = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-footer .level {
  padding-bottom: 0.75rem
}
</style>
