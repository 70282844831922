<template>
  <section
    class="error-manager"
    :class="{ 'is-active': errors.length > 0, 'is-window': errors.length > 1 }"
  >
    <button
      v-show="errors.length > 1"
      class="button is-danger remove-all-errors-button"
      @click="$store.commit('removeAllErrors')"
    >
      <span class="icon has-text-white"><i class="fa fa-times-circle"></i></span>
      <span>Close {{ errors.length }} Errors</span>
    </button>

    <div
      id="error-scrollbox"
      class="error-scrollbox"
    >
      <div
        v-if="errors.length > 0"
        id="error-message-container"
        class="error-message"
      >
        <b-message
          v-for="(error, index) in errors"
          :key="`${error.time}:${index}`"
          :title="error.status ? `(${formatTime(error.time)}) ${error.status}: ${error.statusText}` : `(${formatTime(error.time)}) ${error.title}`"
          type="is-danger"
          @close="$store.commit('removeError', error)"
        >
          <div class="content">
            <strong v-if="error.status">
              {{ error.title }}
            </strong>
            <p
              v-if="error.data"
              class="error-manager__message"
            >Server Message: {{ error.data }}</p>
            <p
              v-if="error.message"
              class="error-manager__message"
            >Client Message: {{ error.message }}</p>
            <details>
              <summary>Additional Details</summary>
              <pre style="white-space: pre-wrap;"><code>{{ JSON.stringify(error, null, 2) }}</code></pre>
            </details>
            <p class="error-manager__message help top25">
              <em>If this error persists, please reach out in the <strong>#cohesion-support</strong> Slack channel for assistance.</em>
            </p>
          </div>
        </b-message>
      </div>
    </div>
  </section>
</template>

<script>
import { formatTime } from '@/modules/utilities';

export default {
  name: 'Errors',
  computed: {
    errors() {
      return this.$store.state.errors;
    }
  },
  updated() {
    if (this.errors.length > 1 && document.body.scrollIntoView !== undefined) {
      const $el = document.getElementById('error-scrollbox');
      if ($el.scrollHeight > $el.clientHeight) {
        // error box is scrollable, scroll newest message into view
        document.getElementById('error-message-container').lastChild.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  methods: {
    formatTime
  }
};
</script>

<style lang="scss" scoped>
.box {
  padding: 0;
}
.error-manager {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  transform: translateY(200%);
  transition: translate .7s;
  transition-timing-function: ease-out;
  z-index: 10;

  .remove-all-errors-button {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    right: 15px;
    top: -35px;
    z-index: 9;
  }

  &__message {
    margin-top: 10px;
  }

  &.is-active {
    padding: 15px;
    transform: none;
  }

  &.is-window {
    background-color: #fafafa;
    border-top: 5px solid #e85043;
  }

  .error-scrollbox {
    max-height: 50vh;
    overflow-y: auto;
  }
}
</style>
