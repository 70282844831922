<template>
  <section>
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <b-field
              label="Bulk Unassign"
              message="Unassign any quantity of numbers from pool."
            >
              <b-input
                v-model="quantity"
                placeholder="Quantity ex: 50"
                type="number"
              />
              <p class="control">
                <button
                  class="button is-warning"
                  :class="{ 'is-loading': loading }"
                  :disabled="quantity <= 0"
                  @click="save"
                >
                  <span class="icon">
                    <i
                      aria-hidden="true"
                      class="fa fa-power-off"
                    />
                  </span>
                  <span>Unassign</span>
                </button>
              </p>
            </b-field>
          </div>
        </div>

        <transition name="fade">
          <section v-if="requestId !== null">
            <div class="notification is-success">
              <span class="icon"><i class="fa fa-check"></i></span>
              Unassign DNIS request received: {{ requestId }}. Redirecting to DNIS History...
            </div>
          </section>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import { sleep } from '@/modules/utilities';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'UnassignNumbers',
  props: ['poolId'],
  data() {
    return {
      quantity: 0,
      loading: false,
      requestId: null
    };
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        const res = await fuseServer.postUnassignDNISBatch(this.poolId, parseInt(this.quantity, 10));

        this.loading = false;
        this.requestId = res.data.requestId;

        await sleep(2000);
        this.$emit('batch-successful', true);
      } catch(err) {
        this.$store.commit('error', err, 'There was a problem unassigning DNIS from Fuse.');
        this.reset();
      }
      this.loading = false;
    }
  }
};
</script>
