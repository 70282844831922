<template>
  <div>
    <table class="table">
      <tbody>
        <tr
          v-for="([k, v]) in displayData"
          :key="k"
        >
          <td><strong>{{ k }}</strong></td>
          <td>
            <b-field>
              <b-switch
                v-if="k === 'resizeEligible'"
                v-model="editData[k]"
                true-value="1"
                false-value="0"
                type="is-info"
              />
              <b-switch
                v-else-if="k === 'reserved'"
                v-model="editData[k]"
                true-value="1"
                false-value="0"
                type="is-info"
                :disabled="true"
              />
              <b-select
                v-else-if="k==='phoneSystemSiteId'"
                v-model="editData[k]"
                placeholder="Select a Phone System Configuration"
              >
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="config.id"
                >
                  {{ `${config.id} - ${config.routingLabel}` }}
                </option>
              </b-select>

              <b-input
                v-else-if="k === 'poolId' || k === 'clientKey'"
                v-model="editData[k]"
                :value="v"
                :disabled="true"
              ></b-input>

              <b-select
                v-else-if="k==='leaseDuration'"
                v-model="editData[k]"
                placeholder="Select a duration"
              >
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="1"
                >
                  1 hr
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="2"
                >
                  2 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="3"
                >
                  3 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="6"
                >
                  6 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="12"
                >
                  12 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="24"
                >
                  24 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="36"
                >
                  36 hrs
                </option>
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="48"
                >
                  48 hrs
                </option>
              </b-select>

              <b-input
                v-else
                v-model="editData[k]"
                :value="v"
              ></b-input>
            </b-field>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <button
      class="button is-success"
      @click="handleEditPool"
    >
      <span class="icon">
        <i
          aria-hidden="true"
          class="fa fa-cloud-upload"
        ></i>
      </span>
      <span>Save Pool</span>
    </button>
    <button
      class="button is-info"
      @click="resetFields"
    >
      <span class="icon">
        <i
          aria-hidden="true"
          class="fa fa-undo"
        ></i>
      </span>
      <span>Reset fields</span>
    </button>
    <button
      class="button is-danger"
      style="float: right;"
      @click="handleDeletePool"
    >
      <span class="icon">
        <i
          aria-hidden="true"
          class="fa fa-times-circle"
        ></i>
      </span>
      <span>Delete Pool</span>
    </button>
  </div>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';
import fieldsToHide from '@/constants/editPool';

export default {
  name: 'EditPool',
  props: ['data', 'defaultMarketingCode'],
  data() {
    return {
      editData: {},
      phoneSystemConfigs: []
    };
  },
  computed: {
    displayData () {
      return Object.entries(this.data).filter(([k]) => {
        return !fieldsToHide.includes(k);
      });
    }
  },
  created() {
    this.resetFields();
    this.getPhoneSystemConfigs();
  },
  methods: {
    async handleEditPool() {
      const updatePacket = {
        poolId: parseInt(this.editData.poolId, 10),
        name: this.editData.name,
        rvCompanyId: parseInt(this.editData.rvCompanyId, 10),
        phoneSystemSiteId: parseInt(this.editData.phoneSystemSiteId, 10),
        tenantId: this.editData.tenantId,
        logoURL: this.editData.logoUrl,
        resizeMax: parseInt(this.editData.resizeMax, 10),
        resizeMin: parseInt(this.editData.resizeMin, 10),
        resizeEligible: parseInt(this.editData.resizeEligible, 10),
        defaultMarketingCode: parseInt(this.editData.defaultMarketingCode, 10),
        leaseDuration: parseInt(this.editData.leaseDuration, 10)
      };

      try {
        const res = await fuseServer.editPool(this.editData.poolId, updatePacket);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Pool updated successfully',
            type: 'is-success',
            duration: 2000
          });
          bus.$emit('reload-pool-data');
        }
      } catch(err) {
        this.$store.commit('error', err, 'Could not edit pool');
      }
    },
    async getPhoneSystemConfigs() {
      try {
        const res = await fuseServer.getPhoneSystemConfigs();
        this.phoneSystemConfigs = res.data;
      } catch (err) {
        this.$store.commit('error', err, 'Could not get Phone System Configurations.');
      }
    },
    resetFields() {
      this.editData = JSON.parse(JSON.stringify(this.data));
      this.editData.defaultMarketingCode = this.defaultMarketingCode;
    },
    async handleDeletePool() {
      try {
        const res = await fuseServer.deletePool(this.data.poolId);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Pool deleted successfully',
            type: 'is-success',
            duration: 2000
          });
          bus.$emit('reload-pool-data');
        }
      } catch(err) {
        this.$store.commit('error', err, 'Could not delete pool');
      }
    }
  }
};
</script>
