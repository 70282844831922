<template>
  <section>
    <div class="main-view-header">
      <router-link
        class="button is-info"
        to="pool/create"
      >
        <span class="icon is-medium">
          <i class="fa fa-plus"></i>
        </span>
        <span>Create Pool</span>
      </router-link>
      <b-field
        grouped
        style="float: right"
      >
        <div class="fuse-pool-result">
          <b-field>
            <span>{{ poolId }}</span>
          </b-field>
          <b-field padding>
            <span>{{ poolName }}</span>
          </b-field>
        </div>
        <b-field
          label="Search Fuse Pool"
          label-position="on-border"
        >
          <b-input
            v-model="dnis"
            placeholder="Enter DNIS"
          />
        </b-field>
        <b-field>
          <button
            class="button is-info"
            @click.stop="searchFusePool"
          >
            <span class="icon"><i class="fa fa-search"></i></span>
            <span>Search</span>
          </button>
        </b-field>
        <p class="control">
          <button
            class="button is-info"
            @click.stop="refreshPoolData"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-refresh"
              ></i>
            </span>
            <span>Refresh Pool Data</span>
          </button>
        </p>
      </b-field>
    </div>

    <div class="main-view-content">
      <div class="main-content">
        <div class="card">
          <b-table
            class="table--detail"
            :data="data"
            detailed
            detail-key="poolId"
            :loading="isLoading"
            :hoverable="true"
          >
            <b-table-column
              v-slot="props"
              field="poolId"
              label="ID"
              numeric
              sortable
            >
              {{ props.row.poolId }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="name"
              label="Name"
              sortable
            >
              {{ props.row.name }}
            </b-table-column>
            <template
              slot="detail"
              slot-scope="props"
            >
              <PoolTab :data="passIn(props.row)"> </PoolTab>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import PoolTab from '@/views/Pools/PoolTab';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'Dashboard',
  components: {
    PoolTab
  },
  data() {
    return {
      data: [],
      isLoading: false,
      modalData: null,
      leaseHistory: null,
      dnis: '',
      poolId: '',
      poolName: ''
    };
  },
  async created() {
    this.populatePoolsData('yes', 1);
    bus.$on('reload-pool-data', this.refreshPoolData);
  },
  methods: {
    // grab fuse pool ID and name from fuse server and return it on the front end
    async searchFusePool() {
      try {
        const res = await fuseServer.getPoolFromDnis(this.dnis);
        this.poolId = `Fuse ID: ${res.data.poolId}`;
        this.poolName = `Name: ${res.data.poolName}`;
      } catch (err) {
        this.poolId = `Fuse ID: NOT FOUND`;
        this.poolName = `Name: NOT FOUND`;
      }
    },

    async refreshPoolData() {
      this.populatePoolsData('no', 1);
      bus.$emit('reload-stat-data');
      this.passIn(this.data);
    },
    passIn(z) {
      // Needed for creating a copy of the data
      const x = JSON.parse(JSON.stringify(z));
      return x;
    },
    level(val) {
      if (val > 98) return 'has-background-danger';
      if (val > 90) return 'has-background-warning';
      return 'has-background-info';
    }, // TODO: add dnis click instead of search bar
    async populatePoolsData(useCache, useBasic) {
      this.isLoading = true;
      try {
        const res = await fuseServer.getPools(useCache, useBasic);

        this.data = res.data;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$store.commit('error', err, 'Error retrieving pool data');
      }
    }
  }
};
</script>
