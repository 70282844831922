<template functional>
  <base-button-default
    icon="copy"
    text="Duplicate"
    :disabled="disabled || !parent.$store.getters.userCanEdit"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'BaseButtonDuplicate',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
