import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from '@/views/Dashboard';
import App from '../../src/App.vue';
import EditFuseUser from '@/views/Users/EditFuseUser';
import NewPool from '@/views/Pools/NewPool';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Fuse',
      component: App
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/pool/create',
      name: 'NewPool',
      component: NewPool
    },
    {
      path: '/user/edit',
      name: 'EditPoolUser',
      component: EditFuseUser
    }
  ]
});

router.afterEach(function () {
  Vue.nextTick(function () {
    window.tagular('pageView');
  });
});

export default router;
