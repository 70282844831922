import moment from 'moment-timezone';

// Globally register all filters for convenience.
export default {
  install(Vue) {
    const filters = {
      filterArchiveDateTime: function(date) {
        return moment(date).format('Do, MMM YYYY h:,,a');
      },
      filterCapitalize: function (str) {
        if (typeof str !== 'string') {
          console.error(`Vue.filter('capitalize'): expected string but received`, str);
          return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      filterLocalTimeString: function (date) {
        const utc = moment.utc(date).toDate();
        return moment(utc).local().format('YYYY-MM-DD HH:mm:ss');
      }
    };

    Object.keys(filters).forEach(function (filter) {
      Vue.filter(filter, filters[filter]);
    });
  }
};
