<template>
  <section>
    <div class="level bottom0">
      <div class="level-left">
        <div class="level-item">
          <b-input
            v-model="filterColumn"
            icon="fa fa-search"
            placeholder="Filter results by..."
          ></b-input>
        </div>
        <div class="level-item">
          <b-select
            v-model="colFilter"
            value="dnis"
          >
            <option value="leaseId">Lease ID</option>
            <option value="leaseName">Name</option>
            <option value="dnis">DNIS</option>
          </b-select>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-select v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="50">20 per page</option>
          </b-select>
        </div>
      </div>
    </div>

    <div>
      <b-table
        :data="tableFiltered"
        hoverable
        paginated
        pagination-simple
        :per-page="perPage"
        :loading="isLoading"
      >
        <b-table-column
          v-slot="props"
          field="leaseId"
          label="LeaseId"
        >
          {{ props.row.leaseId }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="leaseName"
          label="Lease Name"
        >
          {{ props.row.leaseName }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="dnis"
          label="DNIS"
        >
          <a @click.stop="searchDnis(props.row.dnis)">{{ props.row.dnis }}</a>
        </b-table-column>

        <b-table-column
          v-if="hasRoutingGroups"
          v-slot="props"
          field="routingGroupId"
          label="Routing Group ID"
        >
          {{ props.row.routingGroupId }}
        </b-table-column>

        <b-table-column
          v-if="hasRoutingGroups"
          v-slot="props"
          field="routingGroupName"
          label="Routing Group Name"
        >
          {{ props.row.routingGroupName }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="metadata"
          label="Metadata"
        >
          <button
            class="button is-info"
            @click.stop="setVals(props.row.routingGroupId, props.row.leaseName, props.row.leaseId, props.row.metadata, 'getMetadata')"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-edit"
              ></i>
            </span>
            <span>Show Metadata</span>
          </button>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label="Actions"
        >
          <button
            class="button is-info"
            @click.stop="setVals(props.row.routingGroupId, props.row.leaseName, props.row.leaseId, props.row.metadata, 'update')"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-edit"
              ></i>
            </span>
            <span>Edit</span>
          </button>
        </b-table-column>

        <b-table-column
          v-slot="props"
          label=""
        >
          <button
            class="button is-danger"
            @click.stop="setVals(props.row.routingGroupId, props.row.leaseName, props.row.leaseId, props.row.metadata, 'delete')"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-times"
              ></i>
            </span>
            <span>Delete</span>
          </button>
        </b-table-column>

        <template v-slot:empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p><b-icon
                icon="phone"
                size="is-large"
              ></b-icon></p>
              <p>This Pool has no Permaleases.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>

    <b-modal :active.sync="isModalActiveEdit">
      <UpdatePermalease
        :poolId="data.poolId"
        :routingGroupId="routingGroupId"
        :leaseName="leaseName"
        :metadata="metadataEdit"
        :leaseId="leaseId"
      ></UpdatePermalease>
    </b-modal>

    <b-modal :active.sync="isModalActiveDelete">
      <DeletePermalease
        :poolId="data.poolId"
        :leaseId="leaseId"
      ></DeletePermalease>
    </b-modal>

    <b-modal :active.sync="isModalActiveNumberInfo">
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Phone Number Info</p>
          <button
            class="delete"
            aria-label="close"
            @click.stop="isModalActiveNumberInfo = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <pre>{{ numberInfo }}</pre>
        </section>
      </div>
    </b-modal>

    <b-modal :active.sync="isModalActiveGetMetadata">
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">{{ metaMessage }}</p>
          <button
            class="delete"
            aria-label="close"
            @click.stop="isModalActiveGetMetadata = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <pre>{{ metadataInfo }}</pre>
        </section>
      </div>
    </b-modal>

  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';
import UpdatePermalease from '@/views/Permaleases/UpdatePermalease';
import DeletePermalease from '@/views/Permaleases/DeletePermalease';

export default {
  name: 'Permaleases',
  components: {
    UpdatePermalease,
    DeletePermalease
  },
  props: ['data'],
  data() {
    return {
      permaleaseData: [],
      isLoading: false,
      isModalActiveEdit: false,
      isModalActiveDelete: false,
      isModalActiveNumberInfo: false,
      isModalActiveGetMetadata: false,
      leaseId: null,
      leaseName: null,
      routingGroupId: null,
      metadata: null,
      filterColumn: '',
      colFilter: 'dnis',
      perPage: 10,
      numberInfo: null,
      metadataInfo: null,
      metadataEdit: null,
      hasRoutingGroups: false,
      metaMessage: null
    };
  },
  computed: {
    tableFiltered() {
      let result = this.permaleaseData;
      if (this.filterColumn.length > 0) {
        result = this.permaleaseData.filter((row) => {
          row[this.colFilter] = `${row[this.colFilter]}`;
          return row[this.colFilter].toLowerCase().indexOf(this.filterColumn.toLowerCase()) !== -1;
        });
      }
      return result;
    }
  },
  async created() {
    this.loadPermaleaseData();
    bus.$on('reload-permaleases', this.loadPermaleaseData);
    bus.$on('permalease-modal-edit-close', this.permaleaseModalEditClose);
    bus.$on('permalease-modal-delete-close', this.permaleaseModalDeleteClose);
  },
  methods: {
    permaleaseModalEditClose() {
      this.isModalActiveEdit = false;
    },
    permaleaseModalDeleteClose() {
      this.isModalActiveDelete = false;
    },
    async setVals(routingGroupId, leaseName, leaseId, metadata, action) {
      this.routingGroupId = routingGroupId;
      this.leaseName = leaseName;
      this.leaseId = leaseId;
      this.metadata = JSON.stringify(metadata);

      if (action === 'update') {
        this.updatePermalease(this.data.poolId, this.leaseId);
      } else if (action === 'delete') {
        this.isModalActiveDelete = true;
      } else if (action === 'getMetadata') {
        this.getMetadata();
      }
    },
    async searchDnis(dnis) {
      this.isModalActiveNumberInfo = true;

      try {
        const res = await fuseServer.searchPhoneNumber(dnis);

        if (res.status === 200) {
          this.numberInfo = JSON.stringify(res.data, null, 2);
        }
      } catch(err) {
        this.$store.commit('error', err, 'Error searching DNIS');
      }
    },
    async loadPermaleaseData() {
      this.permaleaseData = [];
      this.isLoading = true;

      try {
        const permaRes = await fuseServer.getPermaleases(this.data.poolId, 'true');

        for (let i = 0; i < permaRes.data.length; i++) {
          this.permaleaseData.push(permaRes.data[i]);
          this.permaleaseData[i].leaseId = this.permaleaseData[i].leaseId.toString();
          if (this.permaleaseData[i].routingGroupId) {
            this.hasRoutingGroups = true;
          }
        }
        this.isLoading = false;
      } catch(err) {
        this.isLoading = false;
        this.$store.commit('error', err, 'Error loading permalease data');
      }
    },
    async updatePermalease() {
      try {
        const permaRes = await fuseServer.getMetadata(this.leaseId);

        this.metadataEdit = JSON.stringify(permaRes.data, null, 2);
        this.isModalActiveEdit = true;
      } catch(err) {
        this.$store.commit('error', err, 'Error updating permalease');
      }
    },
    async getMetadata() {
      this.isModalActiveGetMetadata = true;
      this.metaMessage = 'Loading metadata...';

      try {
        const permaRes =  await fuseServer.getMetadata(this.leaseId);

        this.metadataInfo = permaRes.data;
        this.metaMessage = 'Lease metadata';
      } catch(err) {
         this.$store.commit('error', err, 'Error getting metadata');
      }
    }
  }
};
</script>
