<template>
  <div v-if="routingGroupData.length > 0">
    <div class="level bottom0">
      <div class="level-left">
        <div class="level-item">
          <b-input
            v-model="filterColumn"
            placeholder="Filter results by..."
          ></b-input>
        </div>
        <div class="level-item">
          <b-select
            v-model="colFilter"
            value="name"
          >
            <option value="routingGroupId">routingGroupId</option>
            <option value="name">Name</option>
            <option value="destinationDnis">DNIS</option>
          </b-select>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <b-select v-model="perPage">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="50">50 per page</option>
          </b-select>
        </div>
      </div>
    </div>

    <div>
      <b-table
        :data="tableFiltered"
        :paginated="true"
        :per-page="perPage"
        :loading="isLoading"
        :pagination-simple="true"
      >
        <b-table-column
          v-slot="props"
          field="destinationDnis"
          label="Destination DNIS"
          sortable
        >
          {{ props.row.destinationDnis }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="name"
          label="Name"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="routingGroupId"
          label="Routing Group ID"
          sortable
        >
          {{ props.row.routingGroupId }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="phoneNumberCount"
          label="Phone Numbers"
          sortable
        >
          {{ props.row.phoneNumberCount }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Actions"
        >
          <button
            class="button is-info"
            @click.stop="setVals(props.row.poolId, props.row.routingGroupId, props.row.name, props.row.destinationDnis, 'update')"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-edit"
              ></i>
            </span>
            <span>Edit</span>
          </button>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label=""
        >
          <button
            class="button is-danger"
            @click.stop="setVals(props.row.poolId, props.row.routingGroupId, props.row.name, props.row.destinationDnis, 'delete')"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-times"
              ></i>
            </span>
            <span>Delete</span>
          </button>
        </b-table-column>
      </b-table>
    </div>

    <b-modal :active.sync="isModalActiveEdit">
      <UpdateRoutingGroup
        :poolId="poolId"
        :routingGroupId="routingGroupId"
        :name="name"
        :destinationDnis="destinationDnis"
      ></UpdateRoutingGroup>
    </b-modal>
    <b-modal :active.sync="isModalActiveDelete">
      <DeleteRoutingGroup
        :poolId="poolId"
        :routingGroupId="routingGroupId"
      ></DeleteRoutingGroup>
    </b-modal>
    <b-modal :active.sync="isModalActiveSearch">
      <SearchRoutingGroup
        :poolId="poolId"
        :routingGroupId="routingGroupId"
      ></SearchRoutingGroup>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '@/main';
import UpdateRoutingGroup from '@/views/RoutingGroups/UpdateRoutingGroup';
import DeleteRoutingGroup from '@/views/RoutingGroups/DeleteRoutingGroup';
import SearchRoutingGroup from '@/views/RoutingGroups/SearchRoutingGroup';

export default {
  name: 'RoutingGroups',
  components: {
    UpdateRoutingGroup,
    DeleteRoutingGroup,
    SearchRoutingGroup
  },
  props: ['routingGroupData'],
  data() {
    return {
      isModalActiveEdit: false,
      isModalActiveDelete: false,
      isModalActiveSearch: false,
      isLoading: true,
      perPage: 10,
      poolId: null,
      routingGroupId: null,
      name: null,
      destinationDnis: null,
      colFilter: 'name',
      filterColumn: ''
    };
  },
  computed: {
    tableFiltered() {
      let result = this.routingGroupData;
      if (this.filterColumn.length > 0) {
        result = this.routingGroupData.filter((row) => {
          row[this.colFilter] = `${row[this.colFilter]}`;
          return row[this.colFilter].indexOf(this.filterColumn) !== -1;
        });
      }
      return result;
    }
  },
  mounted() {
    bus.$on(
      'close-delete-routing-group-modal',
      this.closeDeleteRoutingGroupModal
    );
    bus.$on(
      'close-edit-routing-group-modal',
      this.closeEditRoutingGroupModal
    );
    bus.$on(
      'close-search-routing-group-modal',
      this.closeSearchRoutingGroupModal
    );
    this.isLoading = false;
  },
  methods: {
    closeDeleteRoutingGroupModal() {
      this.isModalActiveDelete = false;
    },
    closeEditRoutingGroupModal() {
      this.isModalActiveEdit = false;
    },
    closeSearchRoutingGroupModal() {
      this.isModalActiveSearch = false;
    },
    setVals(poolId, routingGroupId, name, destinationDnis, action) {
      this.poolId = poolId;
      this.routingGroupId = routingGroupId;
      this.name = name;
      this.destinationDnis = destinationDnis;
      if (action === 'update') {
        this.isModalActiveEdit = true;
      } else if (action === 'delete') {
        this.isModalActiveDelete = true;
      } else if (action === 'search') {
        this.isModalActiveSearch = true;
      }
    }
  }
};
</script>
