const auth = require('./auth');
const axios = require('axios');
const config = require('../config');
const jwt = require('jsonwebtoken');
const { FUSE_SERVER_ENDPOINT } = config[process.env.NODE_ENV];
const { JWT_KEY } = config;

let activeRequests = 0;
const axiosConfig = {
  baseURL: FUSE_SERVER_ENDPOINT,
  timeout: process.env.CLIENT_AXIOS_TIMEOUT || 120000
};

// Create the Fuse instance and assign an interceptor for getting the required JWT header.
const fuseServer = axios.create(axiosConfig);

fuseServer.interceptors.request.use(function (cfg) {
  // Add Authorization Headers with JWT for all requests
  const requestHeaders = {
    Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`
  };

  cfg.headers = requestHeaders;

  // Trigger the loading indicator
  activeRequests += 1;
  window.vm.$emit('axios-active', activeRequests);

  return cfg;
});

fuseServer.interceptors.response.use(
  function (response) {
  // Test to see if we are active
  activeRequests = (activeRequests > 0) ? activeRequests - 1 : 0;
  window.vm.$emit('axios-active', activeRequests);

  return response;
}, function (err) {
  // Test to see if we are active
  activeRequests = (activeRequests > 0) ? activeRequests - 1 : 0;
  window.vm.$emit('axios-active', activeRequests);

  // Require the user to authenticate again whenever a 401 Unauthorized response is received
  if (err.response && err.response.status === 401) {
    const { responseURL } = err.response.request;

    // only have the user auth if the 401 response was from the server authorize route
    if (responseURL.match(FUSE_SERVER_ENDPOINT) !== null && !responseURL.match('/admin/fuseUser/authorize')) {
      auth.loginUser();
    }
  }

  return Promise.reject(err);
});

async function authorizeUser() {
  const response = await fuseServer.get('/admin/fuseUser/authorize');

  if (response.status === 200) {
    const userJwt = window.localStorage.getItem(JWT_KEY);

    const decodedToken = jwt.decode(userJwt);
    let userData = null;

    if (decodedToken) {
      const firstName = decodedToken['https://firstName'];
      const lastName = decodedToken['https://lastName'];

      userData = { ...response.data, firstName, lastName };
    }

    return userData;
  }

  return null;
}
function getPoolFromDnis(dnis) {
  return fuseServer.get(`/admin/phoneNumbers?dnis=${dnis}`);
}

function getPools(useCache, basic) {
  return fuseServer.get('/admin/pools',
    { params: { useCache, basic } }
  );
}

function getSinglePool(poolId) {
  return fuseServer.get(`/admin/pools/${poolId}`);
}

function addPool(data) {
  return fuseServer.post('/admin/pools', data);
}

function editPool(poolId, data) {
  return fuseServer.put(`/admin/pools/${poolId}`, data);
}

function deletePool(poolId) {
  return fuseServer.delete(`/admin/pools/${poolId}`);
}

function getRoutingGroups(poolId) {
  return fuseServer.get(`/admin/pools/${poolId}/routingGroups`);
}

function getRoutingGroupLeases(poolId, routingGroupId) {
  return fuseServer.get(`/admin/pools/${poolId}/routingGroups/${routingGroupId}/leases`);
}

function createRoutingGroup(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/routingGroups`, data);
}

function updateRoutingGroup(poolId, routingGroupId, data) {
  return fuseServer.put(`/admin/pools/${poolId}/routingGroups/${routingGroupId}`, data);
}

function deleteRoutingGroup(poolId, routingGroupId) {
  return fuseServer.delete(`/admin/pools/${poolId}/routingGroups/${routingGroupId}`);
}

function searchPhoneNumber(dnis) {
  return fuseServer.get('/admin/phoneNumbers', { params: { dnis } });
}

function createPermalease(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/permaleases`, data);
}

function getPermaleases(poolId, excludeMetadata = 'true') {
  return fuseServer.get(`/admin/pools/${poolId}/permaleases`, { params: { excludeMetadata } });
}

function updatePermalease(poolId, leaseId, data) {
  return fuseServer.put(`/admin/pools/${poolId}/permaleases/${leaseId}`, data);
}

function endPermalease(poolId, leaseId) {
  return fuseServer.delete(`/admin/pools/${poolId}/permaleases/${leaseId}`);
}

function getAvailableToPool() {
  return fuseServer.get('/admin/phoneNumbersAvailableToImport');
}

function getFuseUsers() {
  return fuseServer.get('/admin/fuseUsers');
}

// Not currently used anywhere on the admin.
function getFuseUser(email) {
  return fuseServer.get(`/admin/fuseUsers/${email}`);
}

function deleteFuseUser(email) {
  return fuseServer.delete(`/admin/fuseUsers/${email}`);
}

function createFuseAdmin(data) {
  return fuseServer.post('/admin/fuseUsers', data);
}

function getPoolUsageStats(poolId) {
  return fuseServer.get(`/admin/stats/pool/${poolId}`);
}

function getMetadata(leaseId) {
  return fuseServer.get(`/admin/leases/${leaseId}/metadata`);
}

function getPhoneSystemConfigs() {
  return fuseServer.get('/admin/phoneSystemConfigs');
}

function postValidateCSV(data) {
  return fuseServer.post('/admin/csv/validate', data);
}

function postImportDNISBatch(poolId, quantity) {
  return fuseServer.post(`/admin/pools/${poolId}/dnis/batch-import`, { quantity });
}

function postUnassignDNISBatch(poolId, quantity) {
  return fuseServer.post(`/admin/pools/${poolId}/dnis/batch-unassign`, { quantity });
}

function postImportDNISCSV(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/dnis/csv-import`, data);
}

function postDeleteDNISCSV(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/dnis/csv-delete`, data);
}

function postUnassignDNISCSV(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/dnis/csv-unassign`, data);
}

function postDeletePermaleasesCSV(poolId, data) {
  return fuseServer.post(`/admin/pools/${poolId}/permaleases/csv-delete`, data);
}

function getDNISHistoryList(poolId, entity) {
  return fuseServer.get(`/admin/pools/${poolId}/dnis/history`, {
    params: {
      entity
    }
  });
}

function getDNISHistoryCSV(poolId, historyId, entity) {
  return fuseServer.get(`/admin/pools/${poolId}/dnis/history/${historyId}`, {
    params: {
      entity
    }
  });
}

function getDNISModificationStatus(poolId) {
  return fuseServer.get(`/admin/pools/${poolId}/dnis/status`);
}

function searchDNIS(poolId, { leaseState, dnis, page, pageSize, csv }) {
  return fuseServer.get(`/admin/pools/${poolId}/dnis/search`, {
    params: {
      leaseState,
      dnis,
      page,
      pageSize,
      csv
    }
  });
}

module.exports = {
  addPool,
  authorizeUser,
  createFuseAdmin,
  createPermalease,
  createRoutingGroup,
  deleteFuseUser,
  deletePool,
  deleteRoutingGroup,
  editPool,
  endPermalease,
  getAvailableToPool,
  getDNISHistoryCSV,
  getDNISHistoryList,
  getDNISModificationStatus,
  getFuseUser,
  getFuseUsers,
  getMetadata,
  getPermaleases,
  getPhoneSystemConfigs,
  getPoolUsageStats,
  getPoolFromDnis,
  getPools,
  getRoutingGroupLeases,
  getRoutingGroups,
  getSinglePool,
  postUnassignDNISBatch,
  postDeleteDNISCSV,
  postImportDNISBatch,
  postUnassignDNISCSV,
  postImportDNISCSV,
  postDeletePermaleasesCSV,
  postValidateCSV,
  searchDNIS,
  searchPhoneNumber,
  updatePermalease,
  updateRoutingGroup
};
