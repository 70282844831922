<template>
  <div
    class="level"
    v-bind="$attrs"
  >
    <div class="level-left">
      <slot name="left"></slot>
    </div>

    <div class="level-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'b-level'
};
</script>
