<template>
  <section>
    <h3 class="title"> {{ data.name }}</h3>
    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Total Numbers</p>
          <p class="title">{{ fuseTotals.totalNumbers }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Available Numbers</p>
          <p class="title">{{ fuseTotals.availableNumbers }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Percentage Used</p>
          <p class="title">{{ fuseTotals.percentUsed }}%</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Eligible For Move</p>
          <p class="title">{{ fuseTotals.totalAvailableForMove }}</p>
        </div>
      </div>
    </nav>

    <div class="tabs">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="tab"
          :class="{'is-active': activeTab === tab.id}"
          :disabled="!tab.displayed"
        >
          <a @click.stop="changeTab(tab.id)">
            <span class="icon">
              <i
                class="fa"
                :class="`${tab.icon}`"
              />
            </span>
            <span>{{ tab.label }}</span>
            <strong
              v-if="tab.total"
              class="tab-counter"
            >({{ tab.total }})</strong>
          </a>
        </li>
      </ul>
    </div>

    <section class="wrapper">
      <div v-if="activeTab === 'pool-info'">
        <PoolInfo
          :poolId="data.poolId"
          :poolUsage="poolUsage"
        />
      </div>
      <div v-else-if="activeTab === 'edit-pool'">
        <EditPool
          :data="data"
          :defaultMarketingCode="poolUsage.defaultMarketingCode"
        />
      </div>
      <div v-else-if="activeTab === 'phone-numbers'">
        <PhoneNumbers :poolId="data.poolId" />
      </div>
      <div v-else-if="activeTab === 'routing-groups' && routingGroupData.length > 0">
        <RoutingGroups :routingGroupData="routingGroupData" />
      </div>

      <div v-else-if="activeTab === 'permaleases'">
        <button
          class="button is-info"
          @click.stop="isModalActiveCreatePermalease = true"
        >
          <span class="icon">
            <i
              aria-hidden="true"
              class="fa fa-plus"
            ></i>
          </span>
          <span>Create Permalease</span>
        </button>
        <hr>

        <Permaleases :data="data" />
      </div>

      <div v-else-if="activeTab === 'phone-ops'">
        <PhoneOps
          :poolId="data.poolId"
          :poolStats="fuseTotals.countPerPool"
        />
      </div>
    </section>

    <b-modal :active.sync="isModalActiveCreatePermalease">
      <CreatePermalease :data="data"></CreatePermalease>
    </b-modal>
  </section>
</template>

<script>
import { bus } from '@/main';
import EditPool from '@/views/Pools/EditPool';
import PhoneOps from '@/views/Pools/PhoneOps';
import PoolInfo from '@/views/Pools/PoolInfo';
import PhoneNumbers from '@/views/Numbers/PhoneNumbers';
import RoutingGroups from '@/views/RoutingGroups/RoutingGroups';
import Permaleases from '@/views/Permaleases/Permaleases';
import CreatePermalease from '@/views/Permaleases/CreatePermalease';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'PoolTab',
  components: {
    EditPool,
    PhoneOps,
    PoolInfo,
    PhoneNumbers,
    RoutingGroups,
    Permaleases,
    CreatePermalease
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'pool-info',
      routingGroupData: [],
      isModalActiveCreatePermalease: false,
      poolUsage: {}
    };
  },
  computed: {
    tabs() {
      const hasRoutingGroup = this.routingGroupData.length > 0;
      return [
        { id: 'pool-info', label: 'Info', icon: 'fa-info', displayed: true},
        { id: 'edit-pool', label: 'Edit', icon: 'fa-edit', displayed: true},
        { id: 'phone-numbers', label: 'Phone Numbers', icon: 'fa-hashtag', displayed: true },
        { id: 'routing-groups', label: 'Routing Groups', icon: 'fa-object-group', total: this.fuseTotals.routingGroups, displayed: hasRoutingGroup},
        { id: 'permaleases', label: 'Permaleases', icon: 'fa-anchor', total: this.fuseTotals.permaleases, displayed: true},
        { id: 'phone-ops', label: 'Phone Ops', icon: 'fa-phone', displayed: true}
      ];
    },
    fuseTotals() {
      const totals = {
        totalNumbers: this.poolUsage.phoneNumbersTotal,
        availableNumbers: this.poolUsage.phoneNumbersAvailable,
        totalAvailableForMove: this.poolUsage.eligibleForMove,
        permaleases: this.poolUsage.permaleases,
        routingGroups: this.poolUsage.numbersInRoutingGroups,
        percentUsed: 0.0,
        countPerPool: {}
      };

      // get percentage used
      let pctUsed = this.poolUsage.phoneNumbersTotal - this.poolUsage.phoneNumbersAvailable;
      pctUsed /= this.poolUsage.phoneNumbersTotal;
      pctUsed = Math.round(pctUsed * 100);
      // Sometimes there is a divide by zero error
      if (pctUsed) {
        totals.percentUsed = pctUsed;
      } else {
        totals.percentUsed = 0.0;
      }

      const dynamicPoolObj = {};
      dynamicPoolObj.countNumbersToPull = this.poolUsage.eligibleForMove;
      dynamicPoolObj.poolId = this.poolUsage.poolId;
      dynamicPoolObj.rvCompanyId = this.poolUsage.rvCompanyId;

      totals.countPerPool = dynamicPoolObj;

      return totals;
    }
  },
  async created() {
    this.getPoolUsage();
    this.getRoutingGroupData();
    bus.$on('reload-stat-data', this.getPoolUsage);
    bus.$on('close-create-permelease-modal', this.closeCreatePermaleaseModal);
    bus.$on('reload-routing-groups', this.getRoutingGroupData);
  },
  methods: {
    closeCreatePermaleaseModal() {
      this.isModalActiveCreatePermalease = false;
    },
    async getPoolUsage() {
      try {
        const poolResp = await fuseServer.getSinglePool(this.data.poolId);

        this.poolUsage = poolResp.data;
      } catch(err) {
        this.$store.commit('error', err, 'Error getting pool usage');
      }
    },
    async getRoutingGroupData() {
      try {
        const res = await fuseServer.getRoutingGroups(this.data.poolId);

        if (res.status === 200) {
          this.routingGroupData = res.data;
          this.routingGroupData.poolId = this.data.poolId;
        }
      } catch(err) {
         this.$store.commit('error', err, 'Error getting routing groups');
      }
    },
    changeTab(tabId) {
      this.activeTab = tabId;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: 0px;

  .tab[disabled] {
    pointer-events: none;
    opacity: .5;
  }
}
.tab-counter {
  position: relative;
  margin-left: 4px;
}

.wrapper {
  padding: 30px;
  border: 1px solid #ddd;
  border-top: 0;
}
</style>
