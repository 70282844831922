<template>
  <section>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalTitle }}</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section
        v-show="!completed"
        class="modal-card-body"
      >
        <b-field label="Permalease Name">
          <b-input v-model="createPermaleaseObj.leaseName"></b-input>
        </b-field>
        <b-field label="Routing Group ID (Optional)">
          <b-input v-model="createPermaleaseObj.routingGroupId"></b-input>
        </b-field>
        <b-field>
          <button
            v-if="!addFields"
            class="button"
            @click="addFields = true"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-plus"
              ></i>
            </span>
            <span>Add Metadata</span>
          </button>
        </b-field>
        <table
          v-show="Object.keys(validMetaData).length > 0"
          class="table is-hoverable is-fullwidth"
        >
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(v,k) in validMetaData"
              :key="k"
            >
              <td>{{ k }} </td>
              <td>
                {{ v }}
                <button
                  class="button is-pulled-right"
                  @click.stop="removeMetaElement(k)"
                >
                  <span class="icon has-text-danger">
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                    ></i>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <b-field
          v-if="addFields"
          grouped
        >
          <b-input
            v-model="key"
            placeholder="key"
          ></b-input>
          <b-input
            v-model="val"
            placeholder="val"
          ></b-input>
          <p class="control">
            <button
              :disabled="isDisabled"
              class="button is-info"
              @click.stop="addMeta"
            >
              <span class="icon">
                <i
                  aria-hidden="true"
                  class="fa fa-plus"
                ></i>
              </span>
              <span>Add Pair</span>
            </button>
          </p>
        </b-field>

      </section>
      <pre v-if="resp"><code>{{ resp }}</code></pre>
      <footer
        v-if="!completed"
        class="modal-card-foot"
      >
        <button
          class="button is-info"
          @click="createPermalease"
        >Create Permalease</button>
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'CreatePermalease',
  props: ['data'],
  data() {
    return {
      createPermaleaseObj: {},
      key: null,
      val: null,
      metadata: {},
      addFields: false,
      resp: null,
      completed: false,
      modalTitle: 'Create Permalease'
    };
  },
  computed: {
    validMetaData() {
      const result = {};
      for (const v in this.metadata) {
        if (this.metadata[v] !== null) {
          result[v] = this.metadata[v];
        }
      }
      return result;
    },
    isDisabled() {
      return (
        this.key === ''
        || this.val === ''
        || this.key === null
        || this.val === null
      );
    }
  },
  methods: {
    removeMetaElement(element) {
      this.$set(this.metadata, element, null);
    },
    addMeta() {
      this.$set(this.metadata, this.key, this.val);
      this.key = '';
      this.val = '';
    },
    async createPermalease() {
      // Copy to updateRequest so we dont throw an error when we convert metadata from string to object.
      const updateRequest = {};
      try {
        updateRequest.metadata = this.validMetaData;
        updateRequest.routingGroupId = parseInt(
          this.createPermaleaseObj.routingGroupId,
          10
        );
        updateRequest.leaseName = this.createPermaleaseObj.leaseName;
      } catch (e) {
        e.title = 'Invalid request object';
        this.$store.commit('error', e);
        return;
      }

      try {
        const res = await fuseServer.createPermalease(this.data.poolId, updateRequest);

        this.resp = res.data;
        this.modalTitle = 'Permalease created OK';
        this.completed = true;

        bus.$emit('reload-pool-data');
        bus.$emit('reload-permaleases');
      } catch(err) {
        this.closeModal();
        this.$store.commit('error', err, 'Error creating permalease');
      }
    },
    closeModal() {
      bus.$emit('close-create-permelease-modal');
    }
  }
};
</script>

<style scoped>
pre {
  background-color: rgb(179, 255, 203);
}
</style>
