const config = require('../config');
const { JWT_KEY} = config;
/**
 * Awaitable timeout
 * @param {Number} ms time in milliseconds to sleep
 */
function sleep(ms = 2000) {
  return new Promise(function (res) {
    setTimeout(function () {
      res();
    }, ms);
  });
}

/**
 * Format for standard date times
 * @param {String} time - date format
 * @return {String} formatted to (DAY, MO DAY, YEAR, TIME)
 */
 function formatTime(time = Date.now()) {
  const date = new Date(time);

  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}

function createDNISURL(poolId, { leaseState = '', dnis = '' }) {
  if (!poolId) {
    throw {
      title: 'Cannot export CSV',
      message: 'missing poolId'
    };
  }
  const jwt = localStorage.getItem(JWT_KEY);
  const queries = [];
  const fuseServerUrl = config[process.env.NODE_ENV].FUSE_SERVER_ENDPOINT;

  const params = {
    leaseState,
    dnis,
    csv: true,
    jwt
  };

  for (const p in params) {
    if (params[p]) {
      queries.push(`${p}=${params[p]}`);
    }
  }

  return `${fuseServerUrl}/admin/pools/${poolId}/dnis/search?${queries.join('&')}`;
}

module.exports = {
  formatTime,
  sleep,
  createDNISURL
};
