<template>
  <section>
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <section>
              <b-field
                label="Bulk Import"
                message="Import up to the available DNIS in Reserve."
              >
                <b-input
                  v-model="quantity"
                  placeholder="Quantity ex: 50"
                  type="number"
                />
                <p class="control">
                  <button
                    class="button is-success"
                    :class="{ 'is-loading': loading }"
                    :disabled="quantity <= 0 || quantity > available"
                    @click="save"
                  >
                    <span class="icon">
                      <i
                        aria-hidden="true"
                        class="fa fa-plus"
                      />
                    </span>
                    <span>Import</span>
                  </button>
                </p>
              </b-field>
            </section>
          </div>
          <div class="column">
            <section>
              <b-field
                label="Available DNIS"
                message="Total DNIS available in Reserve."
              >
                <b-input
                  v-model="available"
                  disabled
                ></b-input>
                <p class="control">
                  <button
                    class="button is-info"
                    :class="{ 'is-loading': availableLoading }"
                    @click="getAvailableToPool"
                  >
                    <span class="icon">
                      <i class="fa fa-refresh"></i>
                    </span>
                    <span>Refresh</span>
                  </button>
                </p>
              </b-field>
            </section>
          </div>
        </div>

        <transition name="fade">
          <section v-if="requestId !== null">
            <div class="notification is-success">
              <span class="icon"><i class="fa fa-check"></i></span>
              Add DNIS request received: {{ requestId }}. Redirecting to DNIS History...
            </div>
          </section>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import { sleep } from '@/modules/utilities';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'AddNumbers',
  props: ['poolId'],
  data() {
    return {
      quantity: 0,
      available: 0,
      loading: false,
      availableLoading: false,
      requestId: null
    };
  },
  created() {
    this.getAvailableToPool();
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        const res = await fuseServer.postImportDNISBatch(this.poolId, parseInt(this.quantity, 10));

        this.loading = false;
        this.requestId = res.data.requestId;

        await sleep(2000);
        this.$emit('batch-successful', true);
      } catch(err) {
        this.$store.commit('error', err, 'There was a problem adding DNIS to Fuse.');
        this.reset();
      }
      this.loading = false;
    },
    async getAvailableToPool() {
      this.availableLoading = true;

      try {
        const res = await fuseServer.getAvailableToPool();
        this.available = res.data.quantityAvailable;
      } catch (err) {
        this.available = 0;
        this.$store.commit('error', err, 'There was a problem getting available numbers from Fuse.');
      }

      this.availableLoading = false;
    },
    reset() {
      this.quantity = null;
    }
  }
};
</script>
