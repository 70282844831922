<template>
  <div class="is-scrollable">
    <div class="scrollbox">
      <section>
        <b-table :data="data">
          <b-table-column
            v-slot="props"
            field="email"
            label="Email"
            sortable
          >
            {{ props.row.email }}
          </b-table-column>
          <b-table-column v-slot="props">
            <i
              class="fa fa-trash"
              @click.stop="() => {
                isModalActiveDeleteUser = true
                userToDelete = props.row.email
              }"
            ></i>
          </b-table-column>
        </b-table>
        <hr>
        <p class="control">
          <button
            class="button is-info"
            @click.stop="isModalActiveAddUser = true"
          >
            <span class="icon">
              <i
                aria-hidden="true"
                class="fa fa-plus"
              ></i>
            </span>
            <span>Add Fuse Admin</span>
          </button>
        </p>
        <b-modal :active.sync="isModalActiveAddUser">
          <div
            class="modal-card"
            style="width: auto"
          >
            <header class="modal-card-head">
              <p class="modal-card-title">Confirm Add Fuse Admin</p>
            </header>
            <section class="modal-card-body">
              <div class="content">
                <b-field>
                  <b-input
                    v-model="userToAdd"
                    placeholder="Email"
                    icon-pack="fas"
                    icon="envelope"
                  >
                  </b-input>
                </b-field>
                <p class="control">
                  <button
                    class="button is-info"
                    @click.stop="createFuseAdmin"
                  >
                    <span class="icon">
                      <i
                        aria-hidden="true"
                        class="fa fa-plus"
                      ></i>
                    </span>
                    <span>Create User</span>
                  </button>
                </p>
              </div>
            </section>
          </div>
        </b-modal>
        <b-modal :active.sync="isModalActiveDeleteUser">
          <div
            class="modal-card"
            style="width: auto"
          >
            <header class="modal-card-head">
              <p class="modal-card-title">Confirm Delete Fuse Admin</p>
            </header>
            <section class="modal-card-body">
              <div class="content">
                <pre>{{ userToDelete }}</pre>
                <p class="control">
                  <button
                    class="button is-danger"
                    @click.stop="deleteFuseAdmin(userToDelete)"
                  >
                    <span class="icon">
                      <i
                        aria-hidden="true"
                        class="fa fa-times"
                      ></i>
                    </span>
                    <span>Delete</span>
                  </button>
                </p>
              </div>
            </section>
          </div>
        </b-modal>
      </section>
    </div>
  </div>
</template>

<script>
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'EditFuseUser',
  data() {
    return {
      data: [],
      isModalActiveDeleteUser: false,
      isModalActiveAddUser: false,
      userToDelete: null,
      userToAdd: null
    };
  },
  async created() {
    this.getFuseUsers();
  },
  methods: {
    async createFuseAdmin() {
      try {
        const res = await fuseServer.createFuseAdmin({ email: this.userToAdd });

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Create Fuse user OK',
            type: 'is-success',
            duration: 2000
          });
        }

        this.getFuseUsers();
        this.isModalActiveAddUser = false;
      } catch(err) {
         this.$store.commit('error', err, 'Error creating Fuse admin');
      }
    },
    async deleteFuseAdmin(email) {
      try {
        const res = await fuseServer.deleteFuseUser(email);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Delete Fuse user OK',
            type: 'is-success',
            duration: 2000
          });
        }
        this.getFuseUsers();
        this.isModalActiveDeleteUser = false;
      } catch(err) {
         this.$store.commit('error', err, 'Error deleting Fuse admin');
      }
    },
    async getFuseUsers() {
      try {
        const res = await fuseServer.getFuseUsers();
        this.data = res.data;
      } catch(err) {
         this.$store.commit('error', err, 'Error getting Fuse users');
      }
    }
  }
};
</script>
