<template>
  <div>
    <div class="column">
      <b-field label="CSV Upload">
        <b-field
          v-if="file === null"
          class="file"
        >
          <b-upload
            type="is-info"
            drag-drop
            @input="selected"
          >
            <section class="upload-content">
              <div class="content has-text-centered">
                <p>
                  <span class="icon is-medium"><i class="fa fa-upload"></i></span>
                  <span>Drop a .csv file here or Click to upload.</span>
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <b-field v-else>
          <div class="notification is-info has-text-centered">
            <button
              class="delete"
              @click="removed"
            />
            <span class="icon is-medium"><i class="fa fa-file"></i></span>
            <span>{{ file.name }}</span>
          </div>
        </b-field>
      </b-field>
    </div>

    <div class="column">
      <b-field
        label="File Preview"
        message="Preview of the first 20 records to process."
      >
        <div class="content">
          <div
            v-if="preview.error !== null"
            class="notification is-danger has-text-centered"
          >
            <p><strong>Error:</strong> {{ preview.error }}</p>
          </div>
          <b-table
            v-else
            :data="preview.data"
            :columns="preview.columns"
            narrowed
            striped
            bordered
          >
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Upload a CSV to Preview</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </b-field>
    </div>

    <b-loading
      :active.sync="isLoading"
      :is-full-page="false"
    ></b-loading>
  </div>
</template>

<script>
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'DNISCSVUpload',
  props: ['file', 'valid'],
  data() {
    return {
      preview: {
        data: [],
        columns: [
          { field: 'dnis', label: 'DNIS', width: '20%' },
          { field:'leaseName', label:'Lease Name', width: '25%' },
          { field:'metadata', label:'Metadata' }
        ],
        error: null
      },
      isLoading: false
    };
  },
  watch: {
    file(file) {
      if (this.file !== null) {
        this.createPreview(file);
      } else {
        this.preview.data = [];
        this.preview.error = null;
      }
    }
  },
  methods: {
    // The file was removed.
    removed() {
      this.preview.data = [];
      this.preview.error = null;
      this.$emit('update:file', null);
      this.$emit('update:valid', false);
    },
    /**
     * Get a preview of the data for a selected file.
     * If we successfully preview it, consider it a valid file.
     * @param {File} file
     */
    async createPreview(file) {
      this.isLoading = true;
      try {
        const data = new FormData();
        data.append('file', file);

        const res = await fuseServer.postValidateCSV(data);

        this.preview.data = res.data.results.map(function(result) {
          result.metadata = JSON.stringify(result.metadata, null, 2);
          return result;
        });

        this.$emit('update:valid', true);
        this.$emit('update:file', file);
      } catch (err) {
        this.preview.error = err.response.data.error;
        this.$emit('update:valid', false);
      }
      this.isLoading = false;
    },
    /**
     * When a file is selected, update that file in the parent.
     * @param {File} file
     */
    selected(file) {
      this.$emit('update:file', file);
    }
  }
};
</script>

<style lang="scss">
.upload {
  width: 100% !important;
}

.upload-draggable {
  width: 100% !important;
}

.upload-content {
  padding-top: 18px;
  padding-bottom: 18px;
}

.download {
  cursor: pointer;

  &:hover {
    background-color: #1496ed;
  }
}
</style>
