<template>
  <div
    id="app"
    class="app"
  >
    <Login v-if="userNotFound || (userRequested && !user && !userNotFound)" />
    <main
      v-else-if="userRequested"
      class="application"
    >
      <!-- Side Navigation Aside -->
      <SideNav />
      <div class="view-container">
        <section class="main-view">
          <!-- Top Navigation -->
          <TopNav
            v-if="user"
            :user="user"
          />

          <LoadingNotifier
            v-if="user || isLoading"
            :is-active="isLoading"
          ></LoadingNotifier>

          <router-view
            v-if="user"
            :user="user"
          ></router-view>
        </section>
      </div>
      <ErrorManager />
    </main>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

import auth from '@/modules/auth';
import ErrorManager from '@/views/App/Errors';
import LoadingNotifier from '@/components/LoadingNotifier';
import Login from '@/views/Login';
import SideNav from '@/views/App/SideNav';
import TopNav from '@/views/App/TopNav';

export default {
  name: 'app',
  components: {
    ErrorManager,
    LoadingNotifier,
    Login,
    SideNav,
    TopNav
  },
  computed: {
    // Get the user
    user() {
      return this.$store.state.user;
    },
    // Check if we are making any requests
    isLoading() {
      return this.$store.state.axiosActive;
    },
    userNotFound() {
      return this.$store.state.userNotFound;
    },
    userRequested: sync('userRequested')
  },
  async created() {
    await this.authUser();
    this.userRequested = true;
  },
  methods: {
    async authUser() {
      try {
        auth.checkAuth();
        await this.$store.dispatch('getCurrentUser');

        if (this.userNotFound) {
          if (this.$route.path !== '/') {
            this.$router.push('/');
          }
          return;
        }

        if (this.user) {
          // Get hash route from local storage
          const hashRouteKey = 'tmp_auth_redirect_hash';
          let hashRoute = localStorage.getItem(hashRouteKey);
          localStorage.removeItem(hashRouteKey);
          if (!hashRoute || hashRoute === '#/') {
            hashRoute = '#/dashboard';
          }
          // Redirect to hashRoute (substring off '#')
          if (this.$route.path !== hashRoute.substr(1)) {
            this.$router.push(hashRoute.substr(1));
          }
          return;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss">
  @import './assets/scss/project.scss';

  .welcome-container {
    padding: 15px 30px;
  }
</style>
