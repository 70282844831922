<template>
  <section>
    <div class="level">
      <div class="level-left">
        <div class="level-item"><strong>Search:</strong></div>
        <div class="level-item">
          <b-field grouped>
            <b-field
              label="Lease State"
              label-position="on-border"
            >
              <b-select
                v-model="query.leaseState"
                value="all"
              >
                <option value="all">All</option>
                <option value="unleased">Unleased</option>
                <option value="visitor">Visitor</option>
                <option value="permalease">Permalease</option>
              </b-select>
            </b-field>
            <b-field
              label="DNIS"
              label-position="on-border"
            >
              <b-input
                v-model.trim="query.dnis"
                placeholder="Enter DNIS"
              />
            </b-field>
            <b-field>
              <button
                class="button is-info"
                @click.stop="getPhoneNumbers"
              >
                <span class="icon"><i class="fa fa-search"></i></span>
                <span>Search</span>
              </button>
            </b-field>
          </b-field>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button is-info"
            @click.stop="download"
          >
            <span class="icon"><i class="fa fa-download"></i></span>
            <span>Export CSV</span>
          </button>
        </div>
      </div>
    </div>

    <b-table
      :data="results.data"
      :columns="results.columns"
      :per-page="query.perPage"
      :total="results.total"
      :loading="loading"
      paginated
      pagination-simple
      backend-pagination
      bordered
      @page-change="onPageChange"
    >
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Phone Number Records Found</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import fuseServer from '@/modules/fuse_server';
import { createDNISURL } from '@/modules/utilities';

export default {
  name: 'PhoneNumbers',
  props: {
    poolId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      results: {
        data: [],
        columns: [
          { field: 'phoneNumberId', label: 'ID' },
          { field: 'dnis', label: 'DNIS' },
          { field: 'leaseState', label: 'Lease State' },
          { field: 'lastLeaseId', label: 'Lease ID' },
          { field: 'createdAt', label: 'Created At'},
          { field: 'updatedAt', label: 'Updated At'}
        ],
        total: 0
      },
      query: {
        dnis: '',
        leaseState: 'all',
        page: 1,
        pageSize: 20
      },
      loading: false
    };
  },
  created() {
    this.getPhoneNumbers();
  },
  methods: {
    async getPhoneNumbers() {
      this.loading = true;

      try {
        const res = await fuseServer.searchDNIS(this.poolId, { ...this.query });
        this.results.data = res.data.records;
        this.results.total = res.data.meta.totalRecords;
      } catch (err) {
        this.$store.commit('error', err, 'Could not get DNIS from Fuse.');
      }
      this.loading = false;
    },
    download() {
      try {
        window.location.href = createDNISURL(this.poolId, { ...this.query });
      } catch (err) {
        this.$store.commit('error', err, `Could not export CSV: ${err}`);
      }
    },
    onPageChange(page) {
      this.query.page = page;
      this.getPhoneNumbers();
    }
  }
};
</script>
