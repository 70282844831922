<template>
  <div>
    <section class="section">
      <div class="columns">
        <div class="column is-one-quarter">
          <template v-for="(group, name) in nav">
            <nav
              :key="name"
              class="panel is-info"
            >
              <p class="panel-heading">
                {{ group.label }}
              </p>
              <a
                v-for="tab in group.tabs"
                :key="tab.key"
                class="panel-block"
                :class="{ 'is-active': activeTab === tab.key }"
                @click.stop="changeTab(tab.key)"
              >
                <span class="panel-icon">
                  <i :class="`fa fa-${tab.icon}`"></i>
                </span>
                {{ tab.label }}
              </a>
            </nav>
          </template>
        </div>
        <div class="column">
          <section
            v-if="activeTab === 'dnis-import'"
            class="wrapper"
          >
            <h4 class="title is-4">Import DNIS</h4>
            <article class="message">
              <div class="message-body">
                Assign DNIS to the current pool. Bulk import will pull from the Reserve pool. Specific DNIS can be imported using CSV upload.
              </div>
            </article>
            <section>
              <AddNumbers
                :poolId="poolId"
                @batch-successful="changeTab('history')"
              />
              <hr>
              <DNISCSVForm
                key="import-dnis"
                :poolId="poolId"
                method="import"
                entity="pool"
                @upload-successful="changeTab('history')"
              />
            </section>
          </section>
          <section
            v-if="activeTab === 'dnis-unassign'"
            class="wrapper"
          >
            <h4 class="title is-4">Unassign DNIS</h4>
            <section>
              <article class="message">
                <div class="message-body">
                  Move DNIS from the current pool into the Reserve pool. Bulk unassign will move DNIS starting with those that have leases that end the soonest. Specific DNIS can be unassigned using CSV upload.
                </div>
              </article>
              <UnassignNumbers
                :poolId="poolId"
                @batch-successful="changeTab('history')"
              />
              <hr>
              <DNISCSVForm
                key="unassign-dnis"
                :poolId="poolId"
                method="unassign"
                entity="pool"
                @upload-successful="changeTab('history')"
              />
            </section>
          </section>
          <section
            v-else-if="activeTab === 'dnis-delete'"
            class="wrapper"
          >
            <h4 class="title is-4">Delete DNIS</h4>
            <section>
              <article class="message">
                <div class="message-body">
                  Remove the DNIS from the current pool and make it unleasable.
                </div>
              </article>
              <DNISCSVForm
                key="delete-dnis"
                :poolId="poolId"
                method="delete"
                entity="pool"
                @upload-successful="changeTab('history')"
              />
            </section>
          </section>
          <section
            v-else-if="activeTab === 'dnis-history'"
            class="wrapper"
          >
            <h4 class="title is-4">History</h4>
            <section>
              <DNISHistory
                key="history"
                :poolId="poolId"
                entity="pool"
              />
            </section>
          </section>

          <section
            v-if="activeTab === 'permalease-delete'"
            class="wrapper"
          >
            <h4 class="title is-4">Delete Permaleases</h4>
            <section>
              <article class="message">
                <div class="message-body">
                  Delete permaleases from a pool.
                </div>
              </article>
              <DNISCSVForm
                key="delete-permaleases"
                :poolId="poolId"
                method="delete"
                entity="permaleases"
                @upload-successful="changeTab('permalease-history')"
              />
            </section>
          </section>
          <section
            v-else-if="activeTab === 'permalease-history'"
            class="wrapper"
          >
            <h4 class="title is-4">History</h4>
            <section>
              <DNISHistory
                key="permaleases-history"
                :poolId="poolId"
                entity="permaleases"
              />
            </section>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AddNumbers from '@/views/Numbers/AddNumbers';
import UnassignNumbers from '@/views/Numbers/UnassignNumbers';
import DNISCSVForm from '@/views/DNIS/DNISCSVForm';
import DNISHistory from '@/views/DNIS/DNISHistory';

export default {
  name: 'PhoneOps',
  components: {
    AddNumbers,
    UnassignNumbers,
    DNISCSVForm,
    DNISHistory
  },
  props: {
    poolId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'dnis-import',
      nav: {
        dnis: {
          label: 'DNIS Management',
          tabs: [
            { key: 'dnis-import', label: 'Import', icon: 'upload' },
            { key: 'dnis-unassign', label: 'Unassign', icon: 'power-off' },
            { key: 'dnis-delete', label: 'Delete', icon: 'trash' },
            { key: 'dnis-history', label: 'History', icon: 'book' }
          ]
        },
        permaleases: {
          label: 'Permalease Management',
          tabs: [
            { key: 'permalease-delete', label: 'Delete', icon: 'trash' },
            { key: 'permalease-history', label: 'History', icon: 'book' }
          ]
        }
      }
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 20px;
  border: 1px solid #eee;
}
</style>
