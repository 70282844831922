<template>
  <div class="login-view full-height">
    <div class="content-block full-height">
      <div class="content-container">
        <img
          src="../assets/fuse-logo.svg"
          class="logo"
        />
        <h1 class="title">Welcome to Fuse</h1>
        <b-button
          class="login-btn"
          type="is-info"
          expanded
          :loading="isRedirecting"
          :disabled="isRedirecting"
          @click="login"
        >
          Log In With Auth0
        </b-button>
      </div>
    </div>
    <b-message
      v-if="userNotFound"
      class="user-failed-msg"
      type="is-danger"
    >
      You are not a registered user of Fuse. If you think this is in error or need to request access, contact us in #aerias-support.
    </b-message>
    <b-message
      v-if="userFailed"
      class="user-failed-msg"
      type="is-danger"
    >
      Please ensure you are on the VPN before logging in. If you are on the VPN and still cannot access Fuse, contact us in #aerias-support.
    </b-message>
  </div>
</template>

<script>
import auth from '../modules/auth';
export default {
  name: 'Login',
  data() {
    return {
      isRedirecting: false
    };
  },
  computed: {
    userNotFound() {
      return this.$store.state.userNotFound;
    },
    userFailed() {
      return this.$store.state.userFailed;
    }
  },
  methods: {
    login() {
      this.isRedirecting = true;
      auth.loginUser();
    }
  }
};
</script>

<style lang="scss" scoped>
.login-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8%;
  .content-block {
    background-color: white;
    height: auto;
    max-width: 768px;
    .content-container {
      padding: 48px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .logo {
        width: 40%;
        margin-bottom: 24px;
      }
      .login-btn {
        width: 100%;
        ::v-deep button {
          height: 48px;
          font-size: 16px;
        }
      }
    }
  }
  .user-failed-msg {
    margin-top: 24px;
  }
}
.full-height {
  height: 100%;
}
</style>
