<template>
  <div>
    <div class="pool-info__chart-container">
      <line-chart
        :messages="{empty: 'No usage stats found'}"
        xtitle="Time"
        ytitle="Numbers"
        :data="chartData"
        width="1080px"
        style="margin: 0 auto;"
      ></line-chart>
    </div>
    <hr>
    <table class="table">
      <tbody>
        <tr
          v-for="(k, v) in poolUsage"
          :key=v
        >
          <td>
            <strong v-if="v === 'phoneSystemSiteId'">phoneSystemConfig</strong>
            <strong v-else>{{ v }}</strong>
          </td>
          <td>
            <p v-if="v === 'phoneSystemSiteId'">
              {{ translatePhoneSystemSiteId(k) }}
            </p>
            <p v-else>
              {{ k }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fuseServer from '@/modules/fuse_server';
import { bus } from '@/main';

export default {
  name: 'PoolStats',
  props: {
    poolId: {
      type: Number,
      required: true
    },
    poolUsage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      phoneNumbersAvailable: {},
      chartData: [],
      phoneSystemConfigs: []
    };
  },
  async created() {
    bus.$on('reload-stat-data', this.getUsageStats);

    this.getUsageStats();
    this.getPhoneSystemConfigs();
  },
  methods: {
    async getUsageStats() {
      this.phoneNumbersAvailable = {};
      this.chartData = [];

      try {
        const res = await fuseServer.getPoolUsageStats(this.poolId);

        // get data for past 30 days
        if (res.data && res.data.length) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].timestamp = (res.data[i].timestamp.substring(0, 19).replace('T', ' '));
            this.phoneNumbersAvailable[res.data[i].timestamp] = res.data[i].phoneNumbersAvailable;
          }

          this.phoneNumbersAvailable = { ...this.phoneNumbersAvailable };
          this.chartData.push({ data: this.phoneNumbersAvailable, name: 'Leasable numbers' });
        }
      } catch(err) {
        this.$store.commit('error', err, 'Error getting pool usage stats');
      }
    },
    translatePhoneSystemSiteId(id) {
      let translatedId = `Phone System Site ID: ${id}`;

      this.phoneSystemConfigs.find((config, index) => {
        if (config.id === id) {
          translatedId = `${index} - ${config.routingLabel}`;
        }
      });

      return translatedId;
    },
    // Load the phone system configurations for display.
    async getPhoneSystemConfigs() {
      try {
        const res = await fuseServer.getPhoneSystemConfigs();

        this.phoneSystemConfigs = res.data;
      } catch (err) {
        this.$store.commit('error', err, 'Could not get Phone System Configurations.');
      }
    }
  }
};
</script>

<style lang="scss">
.pool-info__chart-container {
  position: relative;
  width: 100%;
  max-width: 1440px;
}
</style>
