<template>
  <section>
    <div class="card">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <p class="title">Create New Pool</p>
          <section>
            <b-field label="Pool Name">
              <b-input
                v-model="poolName"
                type="text"
                validation-message="Required: Please enter a pool name"
                required
              />
            </b-field>

            <b-field label="RV Company ID">
              <b-input
                v-model="rvCompanyID"
                type="text"
                pattern="[0-9]*"
                validation-message="Required: Please enter a numerical Company ID"
                required
              />
            </b-field>

            <b-field label="Default Marketing Code">
              <b-input
                v-model="defaultMarketingCode"
                type="text"
                pattern="[0-9]*"
                validation-message="Required: Please enter a numerical default Marketing Code ID"
                required
              />
            </b-field>

            <b-field label="Default TenantId">
              <b-input
                v-model="tenantId"
                validation-message="Required: Please enter a Tenant ID"
                required
              />
            </b-field>

            <b-field label="Phone System Configuration">
              <b-select
                v-model="phoneSystemSiteId"
                placeholder="Select a Phone System Configuration"
                validation-message="Required: Please select a phone system configuration"
                required
              >
                <option
                  v-for="config in phoneSystemConfigs"
                  :key="config.id"
                  :value="config.id"
                >
                  {{ config.routingLabel }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Lease Duration">
              <b-select
                v-model="leaseDuration"
                placeholder="Select a Lease Duration"
                validation-message="Required: Please select a lease duration"
                required
              >
                <option
                  :key="leaseDuration"
                  :value="1"
                >
                  1 hr
                </option>
                <option
                  :key="leaseDuration"
                  :value="2"
                >
                  2 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="3"
                >
                  3 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="6"
                >
                  6 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="12"
                >
                  12 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="24"
                >
                  24 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="36"
                >
                  36 hrs
                </option>
                <option
                  :key="leaseDuration"
                  :value="48"
                >
                  48 hrs
                </option>
              </b-select>
            </b-field>

          </section>
          <hr>
          <a
            class="button is-info"
            @click.stop="createNewPool"
          >
            <b-icon icon="plus"></b-icon>
            <span>Create Pool</span>
          </a>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'NewPool',
  data() {
    return {
      poolName: '',
      rvCompanyID: null,
      defaultMarketingCode: null,
      tenantId: '',
      phoneSystemConfigs: [],
      phoneSystemSiteId: null,
      leaseDuration: null
    };
  },
  async mounted() {
    try {
      const res = await fuseServer.getPhoneSystemConfigs();

      this.phoneSystemConfigs = res.data;
    } catch (err) {
      this.showToastError('Could not get Phone System Configurations.');
    }
  },
  methods: {
    showToastError(msg) {
      this.$buefy.toast.open({
        message: msg,
        type: 'is-error',
        duration: 2000
      });
    },
    async createNewPool() {
      const poolPayload = {};

      if (this.poolName.length < 1) {
        this.showToastError('Invalid value for pool name');
        return;
      }

      if (!Number.isNaN(this.rvCompanyID) && this.rvCompanyID !== null && this.rvCompanyID > 0) {
        poolPayload.rvCompanyID = parseInt(this.rvCompanyID, 10);
      } else {
        this.showToastError('Company ID is not a valid number');
        return;
      }

      if (!Number.isNaN(this.defaultMarketingCode) && this.defaultMarketingCode !== null && this.defaultMarketingCode > 0) {
        poolPayload.defaultMarketingCode = parseInt(this.defaultMarketingCode, 10);
      } else {
        this.showToastError('Default marketing code is not a valid number');
        return;
      }

      if (!Number.isNaN(this.phoneSystemSiteId) && this.phoneSystemSiteId !== null && this.phoneSystemSiteId > 0) {
        poolPayload.phoneSystemSiteId = parseInt(this.phoneSystemSiteId, 10);
      } else {
        this.showToastError('Phone System Site Id is not a valid number');
        return;
      }

      if (this.tenantId.length < 1) {
        this.showToastError('Invalid TenantId');
        return;
      }

      if (!Number.isNaN(this.leaseDuration) && this.leaseDuration !== null && this.leaseDuration > 0  && this.leaseDuration <= 48) {
        poolPayload.leaseDuration = parseInt(this.leaseDuration, 10);
      } else {
        this.showToastError('leaseDuration is not valid');
        return;
      }

      poolPayload.name = this.poolName;
      poolPayload.tenantId = this.tenantId;

      try {
        const res = await fuseServer.addPool(poolPayload);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'New pool creation successful',
            type: 'is-success',
            duration: 2000
          });
          setTimeout(() => {
            bus.$emit('reload-pool-data');
          }, 500);
          this.$router.push('/dashboard');
        }
      } catch(err) {
        this.$store.commit('error', err, 'Error creating new pool');
      }
    }
  }
};
</script>
