<template functional>
  <base-button-default
    :loading="loading"
    :disabled="disabled"
    type="success"
    icon="cloud-upload"
    text="Save"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'BaseButtonSave',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
