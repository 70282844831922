export default [
'phoneNumbersTotal',
'phoneNumbersAvailable',
'permaleases',
'pctUsed',
'createdAt',
'updatedAt',
'eligibleForMove',
'numbersInRoutingGroups'
];
