<template>
  <section>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Delete Permalease?</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section class="modal-card-body">
        Are you sure you want to delete Permalease with ID: {{ endLease }} ?
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-danger"
          @click="endPermalease"
        >Delete</button>
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'DeletePermalease',
  props: ['poolId', 'leaseId'],
  data() {
    return {
      endLease: null
    };
  },
  created() {
    this.endLease = this.leaseId;
  },
  methods: {
    async endPermalease() {
      try {
        const res = await fuseServer.endPermalease(this.poolId, this.endLease);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Lease ended success',
            type: 'is-success',
            duration: 2000
          });

          bus.$emit('reload-pool-data');
          bus.$emit('reload-permaleases');
          this.closeModal();
        }
      } catch(err) {
        this.closeModal();
        this.$store.commit('error', err, 'Error creating permalease');
      }
    },
    closeModal() {
      bus.$emit('permalease-modal-delete-close');
    }
  }
};
</script>
