<template>
  <section>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Permalease</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Lease Name</td>
              <td>
                <b-field>
                  <b-input v-model="updatePermaleaseObj.leaseName"></b-input>
                </b-field>
              </td>
            </tr>
            <tr>
              <td>Routing Group ID (Optional)</td>
              <td>
                <b-field>
                  <b-input v-model="updatePermaleaseObj.routingGroupId"></b-input>
                </b-field>
              </td>
            </tr>
            <tr>
              <td>Metadata</td>
              <td>
                <b-field>
                  <b-input
                    v-model="updatePermaleaseObj.metadata"
                    type="textarea"
                  ></b-input>
                </b-field>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-success"
          @click="updatePermalease"
        >Save Changes</button>
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'UpdatePermalease',
  props: ['poolId', 'routingGroupId', 'leaseName', 'leaseId', 'metadata'],
  data() {
    return {
      updatePermaleaseObj: {}
    };
  },
  created() {
    this.updatePermaleaseObj.metadata = this.metadata;
    this.updatePermaleaseObj.routingGroupId = this.routingGroupId;
    this.updatePermaleaseObj.leaseName = this.leaseName;
    this.updatePermaleaseObj.leaseId = this.leaseId;
  },
  methods: {
    async updatePermalease() {
      const temp = {};

      temp.metadata = JSON.parse(this.updatePermaleaseObj.metadata);
      temp.routingGroupId = parseInt(this.updatePermaleaseObj.routingGroupId, 10);
      temp.leaseName = this.updatePermaleaseObj.leaseName;

      try {
        const res = await fuseServer.updatePermalease(this.poolId, this.updatePermaleaseObj.leaseId, temp);

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Update permalease OK',
            type: 'is-success',
            duration: 2000
          });
          bus.$emit('reload-pool-data');
          bus.$emit('reload-permaleases');
          this.closeModal();
        }
      } catch(err) {
        this.closeModal();

        this.$store.commit('error', err, 'Error creating permalease');
      }
    },
    closeModal() {
      bus.$emit('permalease-modal-edit-close');
    }
  }
};
</script>
