const config = require('@/config');

const { LOGIN_URL, LOGIN_CLIENT_ID, LOGIN_AUDIENCE } = config[process.env.NODE_ENV];
const { JWT_KEY } = config;

const redirectURI = window.location.protocol + '//' + window.location.host;

const auth0Endpoint =
  `https://${LOGIN_URL}/authorize?scope=openid profile email&response_type=token&client_id=${LOGIN_CLIENT_ID}&audience=${LOGIN_AUDIENCE}&redirect_uri=${redirectURI}`;
const hashRouteKey = 'tmp_auth_redirect_hash';

function logout() {
  localStorage.removeItem(JWT_KEY);
}

async function loginUser() {
  if (this.hasToken()) this.logout();

  // Store current path in local storage for redirect
  localStorage.setItem(hashRouteKey, window.location.hash);
  window.location.assign(auth0Endpoint);
}

async function checkAuth() {
  const jwt = this.checkUrlForToken();

  if (jwt) {
    window.history.replaceState({}, document.title, window.location.pathname);
    localStorage.setItem(JWT_KEY, jwt);
  }
}

function hasToken() {
  return localStorage.getItem(JWT_KEY) !== null;
}

function getAccessToken() {
  const reg = new RegExp('access_token=([^&#]*)', 'i');
  const string = reg.exec(window.location.href);
  return string ? string[1] : null;
}

function checkUrlForToken() {
  if (window.location.href.includes('access_token=')) {
    const jwt = this.getAccessToken();
    return jwt;
  } else {
    return null;
  }
}

module.exports = {
  loginUser,
  logout,
  checkAuth,
  hasToken,
  getAccessToken,
  checkUrlForToken
};
