<template>
  <section>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Routing Group</p>
        <button
          class="delete"
          aria-label="close"
          @click.stop="closeModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Destination DNIS</td>
              <td>
                <b-field>
                  <b-input v-model="updateRoutingGroupObj.destinationDnis"></b-input>
                </b-field>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>
                <b-field>
                  <b-input v-model="updateRoutingGroupObj.name"></b-input>
                </b-field>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-info"
          @click="updateRoutingGroup"
        >
          <span class="icon">
            <i
              aria-hidden="true"
              class="fa fa-plus"
            ></i>
          </span>
          <span>Update Routing Group</span>
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import fuseServer from '@/modules/fuse_server';

export default {
  name: 'UpdateRoutingGroup',
  props: ['poolId', 'routingGroupId', 'name', 'destinationDnis'],
  data() {
    return {
      updateRoutingGroupObj: {}
    };
  },
  created() {
    this.updateRoutingGroupObj.name = this.name;
    this.updateRoutingGroupObj.destinationDnis = this.destinationDnis;
    this.updateRoutingGroupObj.routingGroupId = this.routingGroupId;
    this.updateRoutingGroupObj.poolId = this.poolId;
  },
  methods: {
    async updateRoutingGroup() {
      this.updateRoutingGroupObj.routingGroupId = parseInt(
        this.updateRoutingGroupObj.routingGroupId,
        10
      );

      try {
        const res = await fuseServer.updateRoutingGroup(
          this.updateRoutingGroupObj.poolId,
          this.updateRoutingGroupObj.routingGroupId,
          JSON.stringify(this.updateRoutingGroupObj)
        );

        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Updated routing group OK',
            type: 'is-success',
            duration: 2000
          });
          this.updateRoutingGroupObj = {};
          bus.$emit('reload-routing-groups');
          this.closeModal();
        }
      } catch(err) {
         this.$store.commit('error', err, 'Error updating routing group');
      }
    },
    closeModal() {
      bus.$emit('close-edit-routing-group-modal');
    }
  }
};
</script>
