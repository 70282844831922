<template>
  <nav
    class="navbar is-danger"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-menu">
      <div class="navbar-end">
        <b-dropdown
          class="navbar-drop"
          position="is-bottom-left"
          :mobile-modal="false"
        >
          <a
            slot="trigger"
            class="navbar-item right1rem"
          >
            <div class="avatar">
              <span class="initials">{{ userInitials }}</span>
            </div>
            <span class="icon">
              <i class="fa fa-caret-down"></i>
            </span>
          </a>

          <b-dropdown-item :custom="true">
            <p>
              <strong>{{ user.firstName }} {{ user.lastName }}</strong><br />
              {{ user.email }}
            </p>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user.permission.isSuper"
            :separator="true"
          />
          <b-dropdown-item
            v-if="user.permission.isSuper"
            :custom="true"
          >
            <p>
              <span>
                <strong v-if="user.permission.isSuper">Super</strong>
              </span>
              <span class="icon has-text-warning">
                <i class="fa fa-star"></i>
              </span>
            </p>
          </b-dropdown-item>
        </b-dropdown>

        <!-- extra navbar item for dropdown spacing -->
        <div class="navbar-item"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import { bus } from '@/main';

export default {
  name: 'TopNav',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userMenuActive: false
    };
  },
  computed: {
    isSuper() {
      return this.$store.getters.isSuperUser;
    },
    // Check if the user has a role that can edit
    userInitials() {
      return this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1);
    }
  },
  methods: {
    /**
     * Emit element click event
     * @param {Object} e - element click event
     */
    elementClick(e) {
      bus.$emit('elem-click', e);
    }
  }
};
</script>

<style lang="scss">
  .navbar {
    position: relative;
    z-index: 10;

    &-drop {
      &-instruction-header {
        outline: none;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;
      }
    }
  }
</style>
